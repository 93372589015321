<template>
  <div class="bodyHome">
    <div v-if="isConnected" class="bodyHome">
      <div class="homeTop1">
        <img class="margin-left30" style="width: var(--56);height: var(--52);"
             :src="icon">
        <span class="margin-left26 font-size36 font-weightBold font-color1B1B1B line-height50">{{ title }}</span>
        <span style="flex: 1"></span>
        <div @click="getAppInfo" class="linkWallet font-size28 line-height40 flex_center oneLine"
             style="margin-right: var(--30)">
          {{ userMobile.length == 0 ? '连接钱包' : firstSixCharacters() + '...' + lastFourCharacters() }}
        </div>
        <div @click="collect" class="linkWallet font-size28 line-height40 flex_center oneLine"
             style="margin-right: var(--30)">
          收藏
        </div>
        <div v-if="false" @click="openDrawer" class="flex_center flex-directionColumn"
             style="padding-right: var(--30);padding-left: var(--30);">
          <div
              style="width: var(--40);height: var(--6);background: #1B1B1B;border-radius: var(--3);margin-top: var(--8);"></div>
          <div
              style="width: var(--40);height: var(--6);background: #1B1B1B;border-radius: var(--3);margin-top: var(--8);"></div>
          <div
              style="width: var(--40);height: var(--6);background: #1B1B1B;border-radius: var(--3);margin-top: var(--8);"></div>
        </div>
      </div>
      <!-- 顶部细节   -->
      <div class="margin-left30 margin-top30 topDivClass positionRelative" style="padding-top: var(--30);">
        <div class="flex_able margin-left30 font-size28 font-colorFFFFFF line-height40"
             style="align-items: start">
          账户ID：{{ userNum }}
        </div>
        <img class="margin-left30 margin-top12" style="width: var(--260);height: var(--20);"
             src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/c79b9c86-56fe-4bca-a2cf-57b40645b46c.png"
             alt="">
        <div class="flex_able margin-left30 font-size28 font-colorFFFFFF line-height40"
             style="align-items: center">
          <span>余额：</span>
          <span class="font-size53 font-colorFFFFFF font-weightBold line-height50">{{ balanceX }}</span>
          <span class="font-size32 margin-top18" style="margin-left: var(--14);">X币</span>
        </div>
        <div class="positionAbsolute" @click=lookViewDetails(0)
             style="top: 0;width: 100%;height: 60%;"></div>
        <div @click="goToSetting" class="positionAbsolute flex_center"
             style="right: 0;top: var(--109);width: var(--100); height: var(--100);">
          <img style="width: var(--44);height: var(--40);"
               src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/010662f5-ddf2-4b1a-98f9-a00fad373541.png">
        </div>
        <!--   领红包入口   -->
        <img v-if="!EncryptUtils.isEmpty(activityImage)" @click="goToRedList" class="positionAbsolute"
             style="top: var(--194);right: -4vw;width: var(--72);"
             :src="activityImage">
        <!--   发红包入口   -->
        <img v-if="showSendRed" @click="goToSendRed" class="positionAbsolute"
             style="top: var(--454);right: -4vw;width: var(--96);height: var(--98);"
             src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/8b787335-50fb-4f32-8252-81984f7a3709.png">
        <!--   返回按键   -->
        <img @click=lookViewDetails(0) class="positionAbsolute"
             style="top: var(--38);right: var(--30);width: var(--14);height: var(--24);"
             src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/685d3d96-9bc9-4937-a446-fc7f3cfaeb72.png">
      </div>
      <!-- 顶部细节   -->
      <div class="gridBanner">
        <div @click="clickFunctionTypeBanner(item)" v-for="item in gridData" :key="item.id" class="itemBanner">
          <img :src="item.imgUrl" alt="item.title" class="item-img"/>
          <div class="item-titleBanner">{{ item.name }}</div>
        </div>
      </div>
      <div v-if="false" style="margin-left:var(--30);margin-top: var(--26);">
        <img style="width: var(--690);height: var(--302);"
             src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/6c94f0e0-2e25-4c19-afa1-3192e427cb54.png">
      </div>
      <div v-if="false" class="powerClass">
        <div>
          <div>
            <img style="width: var(--24);height: var(--32);"
                 src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/375a0004-f03b-4fd0-a16d-88cdc0b7f264.png">
            <span class="font-size28 font-colorFFFFFF">当前总算力</span>
          </div>
        </div>
      </div>
      <div style="margin-top: var(--40); align-items: center" class="margin-left30 flex_able">
        <img style="width: var(--48);height: var(--48);margin-right: var(--20);"
             src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/f8e179a4-e2ec-40ac-b795-68494bee09a0.png">
        <span class="font-size30 font-color1B1B1B font-weightBold line-height42">单币挖矿</span>
      </div>
      <div v-for="(item, index) in coinList" :key="index">
        <div class="coinClass margin-top34" style="padding: var(--40)">
          <div class="flex_able" style="align-items: center">
            <img style="width: var(--82);height: var(--82);"
                 :src=item.icon>
            <span class="font-size40 font-color1B1B1B font-weightBold line-height56 margin-left30">{{
                item.name
              }}</span>
            <span style="flex:1;"></span>
            <span v-if="item.statusPower == 1" class="flex_center font-size30 line-height42"
                  style="width: var(--162);color:#FEAE4F;height: var(--54);background: #FFF4E7;border-radius: var(--12);">进行中</span>
          </div>
          <div class="flex_able" style="align-items: center;margin-top: var(--42);">
            <span class="itemNameClass">年化收益率</span>
            <span class="font-size30 line-height42 font-color1B1B1B font-weightBold">{{
                item.annualYieldRateStr
              }}</span>
          </div>
          <div class="flex_able" style="align-items: center;margin-top: var(--40);">
            <span class="itemNameClass">有效期</span>
            <span class="font-size30 line-height42 font-color1B1B1B font-weightBold">{{ item.validTime }}</span>
          </div>
          <div class="flex_able" style="align-items: center;margin-top: var(--18);">
            <span class="itemNameClass">流量算力</span>
            <span class="font-size50 line-height58 font-weightBold" style="color:#07B498;">{{
                item.powerBalance
              }}P</span>
            <span v-if="false" @click="getPower" class="font-size30 line-height42 underline margin-left34"
                  style="color:#268DFF;text-underline: #026AFC">兑换算力</span>
          </div>
          <div class="flex_able" style="align-items: center;margin-top: var(--32);">
            <span class="itemNameClass">算力收益</span>
            <span class="font-size30 line-height42 font-color1B1B1B font-weightBold">{{
                item.powerIncomeBalance
              }} X</span>
            <span v-if="false" @click="lookViewDetails(index)"
                  class="font-size30 line-height42 underline margin-left38">查看明细</span>
          </div>
          <div v-if="false" @click="goToExtract()"
               class="sureClass font-colorFFFFFF font-size30 line-height42 flex_center margin-top70">
            提取
          </div>
        </div>
      </div>
      <div style="height: var(--40);"></div>
      <OpenBrowser ref="openBrowserRef" @handleChange="openLocationCallback"/>
    </div>
  </div>
</template>

<script>
import {Api} from "@/utils/net/Api";
import {Dialog, Toast} from "vant";
import {CommonConstants} from "../../../config/common_constants";
import OpenBrowser from '@/components/OpenBrowser';
import EncryptUtils from "../../../utils/EncryptUtils";
import {Constants} from "../../../utils/constants";
import Clipboard from "clipboard";

var tp = require('tp-js-sdk')
console.log(tp.isConnected());
// 定义属性和变量
let pressTimer = null;

export default {
  computed: {
    EncryptUtils() {
      return EncryptUtils
    }
  },
  components: {OpenBrowser},
  data() {
    return {
      isConnected: false,
      timeNumber: 1,
      userMobile: "",
      userNum: "--",
      title: "",
      icon: "https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/7f7e6de8-ec15-47a2-84e6-82b1c50ff0e0.png",
      uuid: "",
      pressTimer: {},
      // validTime: "0天",
      // annualYieldRateStr: "0%",
      // powerIncomeBalance: "0",
      // powerBalance: "0",
      // statusPower: "-1",
      coinList: [
        {
          name: "X币",
          icon: "https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/faece8ae-8d99-4eb4-938e-7cfd3589c4e2.png",
          validTime: "0天",
          annualYieldRateStr: "0%",
          powerIncomeBalance: "0",
          powerBalance: "0",
          statusPower: "-1",
        }, {
          name: "X币",
          icon: "https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/faece8ae-8d99-4eb4-938e-7cfd3589c4e2.png",
          validTime: "0天",
          annualYieldRateStr: "0%",
          powerIncomeBalance: "0",
          powerBalance: "0",
          statusPower: "-1",
        }, {
          name: "X币",
          icon: "https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/faece8ae-8d99-4eb4-938e-7cfd3589c4e2.png",
          validTime: "0天",
          annualYieldRateStr: "0%",
          powerIncomeBalance: "0",
          powerBalance: "0",
          statusPower: "-1",
        },
        // {
        //   name: "BTC",
        //   icon: "https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/af422e6b-7056-4061-b7dd-f1102571dadf.png",
        //   validTime: "0天",
        //   annualYieldRateStr: "0%",
        //   powerIncomeBalance: "0",
        //   powerBalance: "0",
        //   statusPower: "-1",
        // }, {
        //   name: "SHIB",
        //   icon: "https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/48423b86-9f0a-4f83-9f64-bab1c4b5c58a.png",
        //   validTime: "0天",
        //   annualYieldRateStr: "0%",
        //   powerIncomeBalance: "0",
        //   powerBalance: "0",
        //   statusPower: "-1",
        // }
      ],
      dappParams: {
        title: "Coin X",
        icon_url: "https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/7f7e6de8-ec15-47a2-84e6-82b1c50ff0e0.png",
        url: Constants.lookCoinX
      },
      balanceX: "0",//X币数量
      gridData: [
        {
          "imgUrl": "https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/66b92ade-5add-4c59-b71a-a2881fe59abc.png",
          "value": "goToTopUpPower",
          "keyType": 3,
          "name": "充值",
        }, {
          "imgUrl": "https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/61cefff4-bacf-41a5-915d-94048db42d4f.png",
          "value": "goToExtract",
          "keyType": 3,
          "name": "提取",
        },
        {
          "imgUrl": "https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/29a7a7c8-8a0f-484f-a01d-ab53d58f5b97.png",
          "value": "goToStoreCoin",
          "keyType": 3,
          "name": "储存",
        },
        {
          "imgUrl": "https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/bb5f22f8-cfba-44bf-8697-1ca42650e6c2.png",
          "value": "getPower",
          "keyType": 3,
          "name": "兑换算力",
        }
      ],
      showSendRed: false,//是否显示发红包入口
      activityImage: "",
      jumpPath: ""
    }
  },
  beforeMount() {
    console.log("beforeMount")
  },
  mounted() {
    console.log("mounted")
  },
  beforeUpdate() {
    console.log("beforeUpdate")
  },
  updated() {
    console.log("updated")
  },
  beforeCreate() {
    console.log("beforeCreate")
  },
  created() {
    console.log("created")
    //开始5
    try {
      //设置标题
      document.title = "Coin X"
    } catch (error) {
      console.log("常规错误:" + error)
    }
    this.title = document.title
    let userMobile = localStorage.getItem(Constants.userMobile);
    if (!EncryptUtils.isEmpty(userMobile)) {
      this.userMobile = userMobile
    }
    let userNum = localStorage.getItem(Constants.userNum);
    if (userNum) {
      this.userNum = userNum
    }
    this.queryUserPowerInfo()
    this.queryPageConfiguration()
    this.queryPowerAccount()
    this.queryIsList()
    this.isConnected = tp.isConnected()
    if (this.isConnected) {
      //说明在tp
    } else {
      Dialog.confirm({
        title: "提示",
        message: '请复制链接到TP中打开',
        confirmButtonText: "复制",
        confirmButtonColor: "#ffffff",
        cancelButtonColor: "#666666",
        messageAlign: "center",
        getContainer: ".bodyHome"
      }).then(() => {
        //复制口令
        var clipboard3 = new Clipboard(".bodyHome", {
          text: function () {
            return Constants.lookCoinX
          }
        });
        clipboard3.on('success', function () {//复制成功执行的回调，可选
          Toast("复制成功");
        });
        clipboard3.on('error', function () {//复制失败执行的回调，可选
          Toast("复制失败");
        })
      }).catch(() => {
        console.log('cancel');
      })
    }
  },
  beforeDestroy() {
    console.log("beforeDestroy")
    this.cancel()
  },
  destroy() {
    console.log("destroy")
  },
  methods: {
    goToSetting() {
      let userMobile = localStorage.getItem(Constants.userMobile);
      if (!EncryptUtils.isEmpty(userMobile)) {
        this.$router.push({
          path: '/accountSetting',
        });
      } else {
        this.getAppInfo()
      }
    },
    goToRedList() {
      let userMobile = localStorage.getItem(Constants.userMobile);
      if (!EncryptUtils.isEmpty(userMobile)) {
        this.$router.push({
          path: '/' + this.jumpPath,
        });
      } else {
        this.getAppInfo()
      }
    },
    goToSendRed() {
      this.$router.push({
        path: '/stuffedRedPack',
        query: {}
      });
    },
    clickFunctionTypeBanner(item) {
      let path = item.value
      let keyType = item.keyType
      if (keyType == "3") {
        // 通过动态方法名执行方法
        this[path]();
      } else {
        Toast("即将开放")
      }
    },
    //去充值
    goToTopUpPower() {
      let userMobile = localStorage.getItem(Constants.userMobile);
      if (!EncryptUtils.isEmpty(userMobile)) {
        this.$router.push({
          path: '/topUpPower',
        });
      } else {
        this.getAppInfo()
      }
    },
    //储存
    goToStoreCoin() {
      let userMobile = localStorage.getItem(Constants.userMobile);
      if (!EncryptUtils.isEmpty(userMobile)) {
        this.$router.push({
          path: '/storeCoin',
        });
      } else {
        this.getAppInfo()
      }
    },
    queryIsList() {
      let params = {
        type: 13//发口令红包权限
      }
      let that = this;
      Api.queryIsList(params).then((result) => {
        if (result.code === 100) {
          that.showSendRed = result.data?.isList
        } else {
          that.showSendRed = false
          // Toast(result.msg);
        }
      })
    },
    queryPowerAccount() {
      let params = {
        assetType: Constants.X_COIN,
      }
      Api.queryPowerAccount(params).then((result) => {
        if (result.code === 100) {
          this.balanceX = result.data?.accountVo?.balance
          this.usdt = result.data?.usdt
        } else {
          // 此处不提示
          // Toast(result.msg);
        }
      })
    },
    checkOpen() {
      this.$refs.openBrowserRef.openBrowser();
    },
    openLocationCallback() {
      //执行操作
      this.getAppInfo()
    },
    firstSixCharacters() {
      return this.userMobile.substring(0, 6);
    },
    lastFourCharacters() {
      return this.userMobile.slice(-4);
    },
    handler() {
      this.timeNumber++
      if (this.timeNumber > 300) {
        //取消
        this.cancel()
      } else {
        this.checkLogin()
      }
    },
    //开始定时器
    start() {
      //开启loading
      // Toast.loading({
      //   forbidClick: true,
      //   duration: 0
      // });
      if (pressTimer === null) {
        pressTimer = setInterval(() => {
          // 执行函数
          this.handler();
        }, 1000);
      }
    },
    // 取消计时器
    cancel() {
      // Toast.clear()
      //恢复
      this.timeNumber = 1
      // 检查计时器是否有值
      if (pressTimer !== null) {
        clearInterval(pressTimer);
        pressTimer = null;
      }
    },
    checkLogin() {
      let params = {
        uuID: this.uuid,
      }
      Api.tpLoginPoll(params).then((result) => {
        console.log('data....', result)
        if (result.code === 100) {
          try {
            let userMobile = result.data.userMobile
            let accessToken = result.data.accessToken
            let userNum = result.data.userNum
            if (userMobile && accessToken && userNum) {
              localStorage.setItem(Constants.userMobile, userMobile);
              localStorage.setItem(Constants.accessToken, accessToken);
              localStorage.setItem(Constants.userNum, userNum);
              console.log("accessToken:" + accessToken)
              console.log("userNum:" + userNum)
              this.userMobile = result.data.userMobile
              this.userNum = result.data.userNum
              //登录成功
              console.log("成功" + userMobile)
              this.queryUserPowerInfo()
              this.queryPageConfiguration()
              this.queryPowerAccount()
              this.queryIsList()
              this.userContactQueryOne()
              //只有成功取消
              this.cancel()
            } else {
              console.log("失败" + userMobile)
            }
          } catch (error) {
            console.log("常规错误:" + error)
          }
        } else {
          Toast(result.msg);
        }
      })
    },
    collect() {
      //收藏
      tp.addDAppToDiscover(this.dappParams);
    },
    getAppInfo() {
      this.cancel()
      // tp.getWallet({ switch: true}).then(console.log)
      this.loginByToken()
    },
    openDrawer() {
      // this.loginByToken()
    },
    lookViewDetails(index) {
      if (this.getB(index)) {
        let userMobile = localStorage.getItem(Constants.userMobile);
        if (!EncryptUtils.isEmpty(userMobile)) {
          this.$router.push({
            path: '/powerIncomeDetail',
          });
        } else {
          this.getAppInfo()
        }
      } else {
        Toast(Constants.OPEN_SOON)
      }
    },
    getPower() {
      if (this.getB(0)) {
        let userMobile = localStorage.getItem(Constants.userMobile);
        if (!EncryptUtils.isEmpty(userMobile)) {
          this.$router.push({
            path: '/getPower',
          });
        } else {
          this.getAppInfo()
        }
      } else {
        Toast(Constants.OPEN_SOON)
      }
    },
    getB(index) {
      console.log(index)
      return true;
    },
    goToExtract() {
      let userMobile = localStorage.getItem(Constants.userMobile);
      if (!EncryptUtils.isEmpty(userMobile)) {
        this.$router.push({
          path: '/walletExtract',
        });
      } else {
        this.getAppInfo()
      }
    },
    loginByToken() {
      ////生成唯一随机字符串(UUID 去中划线-)
      let appNonce = EncryptUtils.uuidMiddle();
      this.uuid = appNonce
      // console.log(appNonce)
      //下面数据是写死的  为了当时测试功能  后期是后端返回
      let paramT = {
        // "message": "{\"from\":\"0x12F4900A1fB41f751b8F616832643224606B75B4\",\"gasPrice\":\"0x6c088e200\",\"gas\":\"0xea60\",\"chainId\":\"1\",\"to\":\"0x1d1e7fb353be75669c53c18ded2abcb8c4793d80\",\"data\":\"0xa9059cbb000000000000000000000000171a0b081493722a5f22ebe6f0c4adf5fde49bd8000000000000000000000000000000000000000000000000000000000012c4b0\"}",
        // "signType": "ethSign",
        // "action": "sign",
        "action": "login",
        "actionId": appNonce,
        "blockchains": [{
          "chainId": "56",
          "network": "ethereum"
        }],
        "desc": "cx",
        "dappIcon": this.icon,
        "dappName": this.title,
        "protocol": "TokenPocket",
        "callbackUrl": CommonConstants.BASE_URL + "/api/tpAuth/tpAuth",
        "version": "2.0"
      }
      window.location.href = "tpoutside://pull.activity?param=" + encodeURIComponent(JSON.stringify(paramT), "utf-8")
      this.start()
    },
    //请求 banner 配置
    queryPageConfiguration() {
      let params = {
        positionType: 5000,
      }
      Api.queryPageConfiguration(params).then((result) => {
        console.log('data....', result)
        if (result.code === 100) {
          try {
            let itemPage = result.data.pageConfigurations[0]
            this.activityImage = itemPage.imgUrl
            this.jumpPath = itemPage.value
          } catch (error) {
            console.log("常规错误:" + error)
          }
        }else {
          this.this.activityImage = ""
        }
      })
    },
    queryUserPowerInfo() {
      let params = {}
      Api.queryUserPowerInfo(params).then((result) => {
        console.log('data....', result)
        if (result.code === 100) {
          try {
            // that.validTime = result.data.validTime
            // that.annualYieldRateStr = result.data.annualYieldRateStr
            // that.powerIncomeBalance = result.data.powerIncomeBalance
            // that.powerBalance = result.data.powerBalance
            // that.statusPower = result.data.status
            result.data.list.forEach((item, index) => {
              this.coinList[index]['validTime'] = item.validTime
              this.coinList[index]['annualYieldRateStr'] = item.annualYieldRateStr
              this.coinList[index]['powerIncomeBalance'] = item.powerIncomeBalance
              this.coinList[index]['powerBalance'] = item.powerBalance
              this.coinList[index]['statusPower'] = item.status
            })
          } catch (error) {
            console.log("常规错误:" + error)
          }
        } else {
          console.log("zhijie" + result)
          // Toast(result.msg);
          //遇到错误就直接清空账号
          localStorage.setItem(Constants.userMobile, "");
          localStorage.setItem(Constants.accessToken, "");
          localStorage.setItem(Constants.userNum, "");
          this.userMobile = ""
        }
      })
    },
  }
}
</script>

<style scoped>
.bodyHome {
  background-color: #F7F7F9;
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.underline {
  position: relative;
  color: #268DFF
}

.underline::after {
  content: "";
  position: absolute;
  bottom: var(--3); /* 根据需要调整下划线的位置 */
  left: 0;
  width: 100%;
  border-bottom: var(--1) solid #268DFF; /* 根据需要调整下划线颜色、宽度和样式 */
}

.homeTop1 {
  width: 100vw;
  height: var(--102);
  background-size: 100% 100%;
  background-image: url("https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/b2575a3e-4f73-4469-b63e-2c791800c400.png");
  display: flex;
  align-items: center;
}

.linkWallet {
  padding-left: var(--20);
  padding-right: var(--20);
  margin-right: var(--56);
  height: var(--56);
  border-radius: var(--14);
  border: var(--2) solid #005FFF;
  color: #005FFF;
}

.powerClass {
  width: var(--690);
  margin-left: var(--30);
  height: var(--162);
  margin-top: var(--22);
  background-size: 100% 100%;
  background-image: url("https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/01db6c48-1dec-4bd5-8d48-ae9e30439d45.png");
}

.coinClass {
  margin-left: var(--30);
  width: var(--690);
  background: #FFFFFF;
  border-radius: var(--24);
}

.itemNameClass {
  flex: 1;
  font-size: var(--28);
  color: #7E90A0;
  line-height: var(--40);
}

.sureClass {
  width: var(--608);
  height: var(--88);
  background: #268DFF;
  border-radius: var(--44);
}

/*弹窗的样式修改开始*/
/deep/ .van-dialog {
  border-radius: var(--10);
}

/deep/ .van-dialog__header {
  color: #1B1B1B;
  font-size: var(--36);
  font-weight: bold;
}

/deep/ .van-dialog__message {
  color: #333333;
  font-size: var(--30);
  padding-left: var(--30);
  padding-right: var(--30);
}

/deep/ .van-dialog__confirm {
  margin-left: var(--34);
  margin-right: var(--22);
  margin-bottom: var(--30);
  background: #268DFF;
  border-radius: var(--44);
  width: var(--272);
  height: var(--88);
}

/deep/ .van-dialog__cancel {
  margin-left: var(--34);
  background: #EEEEEE;
  border-radius: var(--44);
  width: var(--272);
  height: var(--88);
}

/deep/ .van-hairline--top::after {
  border: 0 solid transparent;
}

/deep/ .van-hairline--left::after {
  border: 0 solid transparent;
}

/*弹窗更改样式结束*/

.topDivClass {
  width: var(--690);
  height: var(--242);
  background-size: 100% 100%;
  background-image: url("https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/9fd65f7f-bca6-43df-b93a-d16e278cdcd3.png");
}

.gridBanner {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.itemBanner {
  margin-top: var(--30);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-img {
  width: var(--108);
  height: var(--108);
  object-fit: cover;
}

.item-titleBanner {
  margin-top: var(--20);
  font-size: var(--28);
  color: #333333;
}

.openAuthorizeDialog {
  width: var(--208);
  height: var(--42);
  border-radius: var(--10);
  border: var(--2) solid #FFFFFF;
}

.openAuthorizeClass {
  width: 100%;
  height: var(--42);
}
</style>
