<!-- 在微信里面引导去浏览器打开图层 -->
<template>
  <div>
    <van-overlay :show="show" class="dialog">
      <div class="wrapper" @click.stop>
        <div class="web_download_box">
          <img src="@/common/img/web_download.png" alt=""/>
        </div>
        <div @click="controlWeChat" style="width: 100vw;height: 100vh;margin-top: var(--300);">

        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// import {Toast} from 'vant';

import {Toast} from "vant";

export default {
  data() {
    return {
      show: false,
      isAndroid: false,
      isQQBrowser: false,
      isHuaWei: false,
      isiOS: false,
      isWeiXin: false,
      isQQ: false,
    }
  },
  methods: {
    // 判断客户端
    handleAgent() {
      let u = window.navigator.userAgent;
      this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // IOS
      this.isWeiXin = !!/MicroMessenger/i.test(u); // weixin浏览器
    },
    // 处理不同客户端逻辑
    checkOpen() {
      console.log("checkOpen")
      if (this.isWeiXin) {
        this.controlWeChat()
        console.log("checkOpen--"+"isWeiXin")
      } else if (this.isiOS) {
        console.log("checkOpen--"+"isiOS")
        Toast({
          message: 'IOS版本研发中,敬请期待!',
          duration: 5000,
        });
      } else if (this.isAndroid) {
        console.log("checkOpen--"+"isAndroid")
        this.androidNavigators();
      }
    },
    openBrowser() {
      this.checkOpen()
    },
    controlWeChat() {
      this.show = !this.show;
    },
    // 根据客户端处理不同逻辑
    // checkOpen() {
    //   let u = window.navigator.userAgent;
    //   //先判断微信浏览器
    //   this.isWeiXin = !!/MicroMessenger/i.test(u); // WeiXin应用里面的浏览器
    //   this.isQQ = u.toLowerCase().match(/QQ/i) == "qq"; // QQ应用里面的浏览器
    //   this.isQQBrowser = (u.indexOf("QQBrowser") > -1 || u.indexOf("MQQBrowser") > -1)
    //   //先判断QQ浏览器  他会和QQ冲突
    //   if (this.isQQBrowser) {
    //     console.log("在手机QQ浏览器中运行")
    //     this.androidNavigators();
    //   } else if (this.isWeiXin) {
    //     console.log("在微信应用里面")
    //     this.controlWeChat();
    //   } else if (this.isQQ) {
    //     console.log("在QQ应用里面")
    //     this.controlWeChat();
    //   } else if (this.isMobile()) {
    //     console.log("在手机浏览器里面")
    //     this.isHuaWei = (u.toLowerCase().indexOf('huawei') !== -1)
    //     this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
    //     this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // IOS
    //     if (this.isHuaWei) {
    //       console.log("在手机华为浏览器中运行")
    //       this.androidNavigators();
    //     } else if (this.isAndroid) {
    //       console.log("在安卓设备里面")
    //       this.androidNavigators();
    //     } else if (this.isiOS) {
    //       console.log("在Ios设备里面")
    //       //ios浏览器 直接执行下载
    //       this.openLocationDownloadCallback()
    //     }
    //   } else {
    //     console.log("在电脑设备里面")
    //     //电脑端 直接执行下载
    //     this.openLocationDownloadCallback()
    //   }
    // },

    //子组件的事件 调用父组件打开原生链接
    openLocationCallback() {
      this.$emit('handleChange') // 触发父组件中handleChange事件并传参Jack
      // 注：此处事件名称与父组件中绑定的事件名称要一致
    },
    // 唤醒App
    androidNavigators() {
      this.openLocationCallback()
    },
  },
  created() {
    this.handleAgent()
  },
}
</script>

<style scoped>
.dialog {
  z-index: 99;
}

.web_download_box {
  position: absolute;
  top: 0;
  right: 7vw;
}

.web_download_box img {
  width: 48.86vw;
  height: 36.33vw;
}
</style>
